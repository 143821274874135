<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog v-model="openDialog" persistent max-width="1000px">
        <v-card>
          <v-toolbar
            color="primary"
            dark
            flat
            style="padding-right: 10px !important"
          >
            <v-toolbar-title class="overline">{{
              titleParticipantModal
            }}</v-toolbar-title>
            <v-btn icon right absolute dark>
              <v-icon @click="closeDialog()">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row class="d-flex justify-start align-end" v-if="eventPaid">
                <v-col cols="12" sm="4" class="mr-n8">
                  <v-switch
                    v-model="participant.payed"
                    :label="participant.payed ? 'Pago? SIM' : 'Pago? NÃO'"
                  ></v-switch>
                </v-col>

                <v-col cols="12" sm="5" class="mt-n8">
                  <span>Preço R$</span>
                  <money
                    class="money"
                    v-model="participant.amount"
                    v-bind="money"
                  ></money>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    outlined
                    :items="types"
                    item-value="id"
                    item-text="title"
                    v-model="participant.typeId"
                    label="Tipo"
                    :loading="loading"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    outlined
                    v-model="participant.name"
                    label="Nome"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="mt-n4">
                  <v-text-field
                    outlined
                    v-mask="'(##)#####-####'"
                    label="Fone"
                    v-model="participant.phone"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="mt-n4">
                  <v-text-field
                    outlined
                    label="Email"
                    v-model="participant.email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="mt-n4">
                  <v-text-field
                    outlined
                    label="Convidante"
                    v-model="participant.invitingName"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="mt-n4">
                  <v-text-field
                    outlined
                    v-mask="'(##)#####-####'"
                    label="Fone Convidante"
                    v-model="participant.invitingPhone"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n4">
                  <v-textarea
                    rows="3"
                    outlined
                    label="Anotações"
                    v-model="participant.notes"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="loadingAction"
                  width="200px"
                  color="#2ca01c"
                  dark
                  @click="registerOrUpdateParticipant()"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="searchParticipants"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                @click="openModalType()"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Participante</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                @click="openModalType()"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headerParticipants"
          :items="participants"
          :search="searchParticipants"
        >
          <template v-slot:[`item.amount`]="{ item }">
            <span> {{ formatPrice(item.amount) }}</span>
          </template>
          <template v-slot:[`item.payed`]="{ item }">
            <v-switch
              small
              v-model="item.payed"
              disabled
              :label="item.payed ? 'Sim' : 'Não'"
            ></v-switch>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="editParticipant(item)">
              <v-icon small class="mr-2">mdi-pencil</v-icon>
            </v-btn>
            <v-icon
              v-if="verifyDeletePermission()"
              small
              class="mr-2"
              @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";

export default Vue.extend({
  name: "Participants",
  components: { InternalMenu, PageHeader, Money },
  data: () => ({
    title: "Participantes",
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Eventos",
        disabled: false,
        href: "/events",
      },
      {
        text: "Participantes",
        disabled: true,
        href: "/events",
      },
    ],
    deleteDialog: false,
    search: "",
    searchParticipants: "",
    members: [],
    loading: false,
    loadingAction: false,
    titleParticipantModal: "Novo Participante",
    types: [],
    eventPaid: false,
    participants: [],
    event: {
      id: null,
      unitPrice: 0.0,
    },
    participant: {
      id: null,
      typeId: null,
      eventId: null,
      name: "",
      phone: "",
      email: "",
      amount: 0.0,
      payed: false,
      invitingName: "",
      invitingPhone: "",
      billed: false,
      notes: "",
    },
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    id: "",
    openDialog: false,
    participantModal: false,
    headerParticipants: [
      {
        text: "Tipo",
        align: "start",
        value: "type.title",
        filtering: true,
      },
      {
        text: "Nome",
        align: "start",
        value: "name",
        filtering: true,
      },
      {
        text: "Fone",
        align: "start",
        value: "phone",
        filtering: true,
      },
      {
        text: "Valor",
        align: "start",
        value: "amount",
        filtering: true,
      },
      {
        text: "Pago",
        align: "start",
        value: "payed",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    formatPrice(value) {
      if (!value || value <= 0) return "Grátis";
      let val = (value / 1).toFixed(2).replace(".", ",");
      return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    registerOrUpdateParticipant() {
      if (this.participant && this.participant.id) {
        http
          .patch(
            `promise/event/participants/${this.participant.id}`,
            this.participant
          )
          .then(
            (res) => {
              this.snackbar.text = "Participante atualizado com sucesso";
              this.snackbar.color = "green";
              this.snackbar.opened = true;
              this.openDialog = false;
              this.getParticipants(this.event);
            },
            () => {
              this.snackbar.text = "Erro ao atualizar participante";
              this.snackbar.color = "red";
              this.snackbar.opened = true;
            }
          );
      } else {
        delete this.participant.id;
        http.post("promise/event/participants", this.participant).then(
          (res) => {
            this.snackbar.text = "Participante adicionado com sucesso";
            this.snackbar.color = "green";
            this.snackbar.opened = true;
            this.openDialog = false;
            this.getParticipants(this.event);
          },
          () => {
            this.snackbar.text = "Erro ao adicionar participante";
            this.snackbar.color = "red";
            this.snackbar.opened = true;
          }
        );
      }
    },
    async getParticipants(event) {
      const eventId = event.id;
      this.event = event;
      this.loading = true;
      http
        .get(`promise/event/participants?filter=eventId||eq||${eventId}`)
        .then(
          (res) => {
            const { data } = res;
            this.participants = data;
            this.loading = false;
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Falha ao buscar participantes";
            this.snackbar.opened = false;
            this.loading = false;
          }
        );
    },
    closeDialog() {
      this.participant.typeId = null;
      this.participant.eventId = null;
      this.participant.name = "";
      this.participant.email = "";
      this.participant.phone = "";
      this.openDialog = false;
    },
    openModalType(event) {
      if (!event && this.event.id) event = this.event;
      this.participant.eventId = event.id;
      this.participant.amount = event.unitPrice;
      this.eventPaid = event.isPaid;
      this.openDialog = true;
    },
    editParticipant(participant) {
      this.participant = participant;
      this.titleParticipantModal = "Atualizar Participante " + participant.name;
      this.openDialog = true;
    },
    getTypes() {
      http.get("promise/event/participant/types").then((res) => {
        const { data } = res;
        this.types = data;
      });
    },
    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    verifyDeletePermission() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.admin) return true;
      if (
        user &&
        user?.group?.permissions &&
        user?.group?.permissions
          ?.map((a) => a.id)
          ?.includes("promise.event.participants.delete_one")
      ) {
        return true;
      }
      return false;
    },
    deleteItem() {
      http.delete(`promise/event/participants/${this.id}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.id = null;
          this.deleteDialog = false;
          this.getParticipants(this.event);
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    async getItems() {
      this.loading = true;
      const eventId = this.$route.params.eventId;
      if (!eventId) {
        this.snackbar.color = "red";
        this.snackbar.text = "Falha ao Localizar Evento";
        this.snackbar.opened = true;
        this.loading = false;
        return;
      }
      http.get(`promise/events/${eventId}`).then(
        async (data) => {
          this.event = data.data;
          this.title = `Participantes ${this.event.title}`;
          await this.getParticipants(this.event);
          this.loading = false;
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Falha ao Localizar Evento";
          this.snackbar.opened = true;
          this.loading = false;
        }
      );
    },
  },
  mounted() {
    this.getItems();
    this.getTypes();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 55px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>
